var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gaugingTubesDetail"},[_c('app-header'),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
      bg: 'gray',
      title: 'label.gaugingTubes',
      type:'gaugingTubesBox',
      businesskey:'gaugingTubes',
      modal:'gaModal',
      data: _vm.gaugingTubes,
      PrID: _vm.workingObject.PrID,
      MpGuid: _vm.workingObject.MpGuid,
      buttons: ['addButton'],
      enableBoxLink: true
    }}}):_vm._e(),(_vm.workingObject && _vm.workingObjectType == 'gaugingTube')?_c('editors',{attrs:{"screen":"frmFiltertubeDetails"}}):_vm._e(),_c('app-footer',{attrs:{"saveComponentDataFunction":_vm.save}}),_c('nv-loading',{attrs:{"show":_vm.loading}}),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }