<template>
  <div class="gaugingTubesDetail">
    <app-header/>
    <boxes
      v-if="workingObject"
      :options="{
        bg: 'gray',
        title: 'label.gaugingTubes',
        type:'gaugingTubesBox',
        businesskey:'gaugingTubes',
        modal:'gaModal',
        data: gaugingTubes,
        PrID: workingObject.PrID,
        MpGuid: workingObject.MpGuid,
        buttons: ['addButton'],
        enableBoxLink: true
      }">
    </boxes>
    <editors v-if="workingObject && workingObjectType == 'gaugingTube'" screen="frmFiltertubeDetails"></editors>
    <app-footer :saveComponentDataFunction="save"/>
    <nv-loading :show="loading"></nv-loading>

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import nvLoading from '@/components/_shared/loading'
import Editors from '@/components/editors'
import boxes from '@/components/boxes'
import gapsRule from '@/utils/gapsRule'
import { GAUGING_TUBE_TYPE } from '@/vuex/constants'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { GaugingTubeService } from '@/services/DataWS/gaugingTubeService'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const gaugingTubeService = new GaugingTubeService()

var self

export default {
  name: 'gaugingTubes',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    ExportProjectPopup
  },
  data () {
    return {
      loading: false,
      PrID: null,
      MpGuid: null,
      exportPopupOpen: false
    }
  },

  computed: {
    gaugingTubes: {
      get () {
        return this.$store.state.gaugingTubes
      },
      set (value) {
        this.$store.commit('setGaugingTubes', value)
      }
    },
    workingObject: {
      get () {
        return this.$store.state.workingObject
      },
      set (value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get () {
        return this.$store.state.workingObjectType
      },
      set (value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get () {
        return this.$store.state.workingObjectIndex
      },
      set (value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentProject: {
      get () {
        return this.$store.getters.getCurrentProject
      }
    },
    currentMeasurementPoint: { // uses mpGuid and measurementpoints to get the current measurementpoint
      get () {
        if (this.measurementPoints) {
          if (this.MpGuid) {
            return this.measurementPoints.find(m => m.MpGuid === this.MpGuid)
          }
        }
        return null
      }
    }
  },

  watch: {
    // on route change, this is NOT called for fresh loads. fresh loads are handled in created()
    '$route' (to, from) {
      this.format()
      // update active gauging tube
      this.setActiveFiltertube()
    }
  },
  created () {
    self = this

    this.MpGuid = this.$route.params.measurementPointId
    this.PrID = this.$route.params.projectId

    let tasks = []

    // if no gauging tubes are loaded, then load
    if (!(_.isArray(this.gaugingTubes) && this.gaugingTubes.length)) {
      tasks.push(gaugingTubeService.getGaugingTubes(this.PrID, this.MpGuid)
        .then((res) => {
          // show loading indicator
          self.loading = true

          res.tblGaugingTubes.forEach(v => (v.type = 'gaugingTubesBox'))
          gapsRule.getSortData(res.tblGaugingTubes, 'FtFilterFrom')
          self.gaugingTubes = res.tblGaugingTubes

          // hide loading indicator
          self.loading = false
        })
        .catch(err => {
          self.loading = false
          console.log(err)
          // this.handlerError(err)
        })
      )
    }

    window.Promise.all(tasks)
      .then(() => {
        // format raw gauging tubes and set active finishing
        this.format()
        return this.setActiveFiltertube()
      })
  },
  methods: {
    format () {
      gapsRule.getSortData(this.gaugingTubes, 'FtFilterFrom')
      this.gaugingTubes = this.gaugingTubes.filter(v => v.type !== 'emptyBox')
      this.gaugingTubes.forEach(g => (g.type = 'gaugingTubesBox'))
    },
    setActiveFiltertube () {
      this.workingObjectIndex = _.findIndex(this.gaugingTubes, { FtID: this.$route.params.FtID })

      this.gaugingTubes.forEach(ed => (ed.isActive = false))

      this.workingObject = this.gaugingTubes[this.workingObjectIndex]
      this.workingObject.isActive = true
      this.workingObjectType = GAUGING_TUBE_TYPE

      return this.setState('FtFilterFrom', 'FtFilterTo')
    },
    setState (from, to) {
      return this.$store.dispatch('setActiveTitle', (this.workingObject[from] || '') + '-' + (this.workingObject[to] || ''))
        .then(r => { return this.$store.dispatch('setPrevTitle', (this.currentMeasurementPoint || {}).MpName) })
    },
    save () {
      this.loading = true
      return workingObjectUtils.pushWorkingObjectToDataService({
        PrID: this.PrID,
        MpGuid: this.MpGuid
      })
        .then(res => {
          self.loading = false
          // update gauging tubes with response from server
          self.gaugingTubes = res
          return true
        })
        .catch(err => {
          self.loading = false
          self.handlerError(err)
        })
    },
    delete () {
      let modalDelete
      modalDelete = $('#modalDeleteGaugingTube')
      modalDelete.modal('toggle')
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>

<style>
  @media (max-width: 640px) {
    .gaugingTubesDetail .edit-panel .input-group.input-left{
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .gaugingTubesDetail .editor-panel {
      width: calc(100vw - 410px);
    }
  }
</style>
